.rcs_sliderSet {
	width: 100%;
}

.rcs_sliderBox {
    overflow-x: hidden;
    border-radius: 2px;
    margin:0 auto;
    display: flex;
    align-items: center;
/*  flex-direction: column;
  	justify-content: center;*/
  	position: relative;
}

.rcs_slideCon {
	position: relative;
	display: flex;
 	align-items: center;
	width: auto;
	/*height: 80%;*/
}

.rcs_flag {
	
	height: 100%;
	background-color: rgba(91, 97, 107, 0.5);
	flex: 1 0;
    margin: 0px;

}

.rcs_slideCon .itemWrapper {
	display: block;
	height: 100%;
	position: relative;
	border-radius: 2px;
	width: auto;
	/*width: 100px;*/
	
}

.rcs_slideCon .itemWrapper img {
	display: block;
	/*height: ;*/
	height: 100%;
	width: auto;
	
}

.rcs_slideCon .itemWrapper a {
	height: 100%;
	width: auto;
}

.rcs_slideCon .itemWrapper div {
	height: 100%;
	width: auto;
}


.rcs_slideCon .itemWrapper p {
	width: 75%;
	color: white;
	padding:10px;
	top: 80%;
	background: rgba(117, 117, 117, .6);
    border-radius: 2px;
    text-align: center;
/*------------------------*/
	position: absolute;
	/*opacity: 1;*/
	left: 0;
	right: 0;
	margin: 0 auto;
	transform: translateY(-50%);	
}

.rcs_buttonSet {	
	display: flex;
	justify-content: center;
	z-index: 2000;
}

.rcs_sliderBox:hover .rcs_buttonWrapper {
	opacity: 1 !important;
}

.rcs_sliderBox:hover ~ .buttonSet .rcs_buttonWrapper {
	opacity: 1 !important;
}

.rcs_arrowBtn {
	/*costum*/
}

.rcs_buttonSet .rcs_buttonWrapper {

	cursor: pointer;
	opacity: .6;
	transition: all 0.3s ease;
	z-index: 2000;
}

.rcs_buttonSet .rcs_buttonWrapper:hover {
	opacity: 1;
}

.rcs_buttonWrapper {

	cursor: pointer;
	opacity: .6;
	transition: all 0.3s ease;
	z-index: 2000;
	user-select: none;
	/*opacity: 0;*/
}

.rcs_previous:before {
	
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	content: '\003c';
}

.rcs_next:before {

	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	content: '\003e';
}

.rcs_right {

	position: absolute;
	right: 0px;

	top: 50%;
	transform: translateY(-50%);
}

.rcs_left {

	position: absolute;
	left: 0px;

	top: 50%;
	transform: translateY(-50%);
}

.rcs_dotsSet {
	width: fit-content;
	z-index: 2000;
}